import React from 'react';
import PropTypes from 'prop-types';
import style from './style.css';
import ButtonBar from './ButtonBar';
import { loadGroups } from '../../reducers/sites.js';
import { connect } from 'react-redux';
import ToolSelector from './ToolSelector';
import DateRangeSelector from './DateRangeSelector';
import Collapsable from './Collapsable';
import CyclePreview from './CyclePreview';
import ChartCard from './ChartCard';
import MeanFidelity from './MeanFidelity';
import moment from 'moment';
import dashboardQuery from '../../graphql/dashboard.graphql';
import youthEnrolledQuery from '../../graphql/dashboardYouthEnrolled.graphql';
import { client } from 'cccisd-apollo';
import IconUser from 'cccisd-icons/user';
import IconStar from 'cccisd-icons/star-full';
import IconCal from 'cccisd-icons/calendar4';
import IconCheck from 'cccisd-icons/checkmark';

const user = window.cccisd.fortress.user.acting;
const isUber = user.data_type === 'uberadmin';
const licenses = {
    dart: !isUber ? user.group.type_data.dartLicense : 1,
    wfi: !isUber ? user.group.type_data.wfiLicense : 1,
    tom: !isUber ? user.group.type_data.tomLicense : 1,
};
const toolPermissions = !isUber ? JSON.parse(user.role_data.toolPermissions) : [];

// consts
const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const now = moment();
const thisMonth = now.month();
const thisYear = now.year() - 2000;

class Dashboard extends React.Component {
    static propTypes = {
        //  from redux
        loadGroups: PropTypes.func,
        checked: PropTypes.array,
    };

    state = {
        collapseAll: false,
        cycleLimit: 10,
        monthsBack: 6,
        assignmentIds: [1, 2, 3, 4, 5, 6],
        cycles: [],
        cyclesLoaded: false,
        youthData: null,
        youthCount: '__',
        youthEnrolled: [],
        currentYouthEnrolled: '__',
        youthDischarged: [],
        youthDischargedInRange: '__',
        meanLengthOfService: [],
        meanLengthForCurrent: '__',
        dischargeOutcomes: [],
        avgSuccessInRange: '__',
        toolTree: [],
        totalCycleCount: null,
        xAxis: [],
    };

    componentDidMount = async () => {
        this.props.loadGroups();
        this.getXaxis();
        this.getToolTree();
        this.getYouthData();
        this.getCycleData();
    };

    componentDidUpdate = async (prevProps, prevState) => {
        if (prevState.assignmentIds !== this.state.assignmentIds || prevState.cycleLimit !== this.state.cycleLimit) {
            this.getCycleData();
        }
        if (prevState.monthsBack !== this.state.monthsBack) {
            this.getXaxis();
            this.updateCharts();
        }
        if (prevProps.checked !== this.props.checked) {
            this.getCycleData();
            this.getYouthData();
        }
        if (prevState.youthData !== this.state.youthData) {
            this.updateCharts();
        }
    };

    getToolTree = () => {
        this.setState({
            toolTree: [
                {
                    value: 100,
                    name: 'wfi',
                    label: 'WFI-EZ',
                    children: [
                        { value: 2, label: 'Care Coordinator' },
                        { value: 3, label: 'Caregiver' },
                        { value: 4, label: 'Team Member' },
                        { value: 5, label: 'Youth' },
                    ],
                },
                { value: 1, name: 'tom', label: 'TOM 2.0' },
                { value: 6, name: 'dart', label: 'DART' },
            ].filter(tab => {
                if (!isUber) {
                    // if the group is allowed
                    if (licenses[tab.name]) {
                        // if the user has permissions set
                        if (toolPermissions && toolPermissions.length > 0) {
                            return toolPermissions.includes(tab.name);
                        }
                        return true;
                    }
                } else {
                    // if uberadmin
                    return true;
                }
                return false;
            }),
        });
    };

    formatLineData = stateField => {
        const xAxis = this.state.xAxis;
        let data = [];
        for (var i = 0; i < this.state.monthsBack; i++) {
            if (this.state[stateField][i] || this.state[stateField][i] === 0) {
                data.push({
                    x: xAxis[i],
                    y: this.state[stateField][i],
                });
            }
        }
        return [
            {
                id: '123',
                color: 'hsl(266, 70%, 50%)',
                data,
            },
        ];
    };

    updateCharts = () => {
        let youthEnrolled = [];
        let youthDischarged = [];
        let meanLengthOfService = [];
        let dischargeOutcomes = [];
        const currentYouthEnrolled = this.state.youthData.youthCount;
        const { youthEnrolledFrequency, youthExitFrequency } = this.state.youthData.youthSummary;
        const { avg } = this.state.youthData.currentSummary;
        const { youthOutcomesFrequency } = this.state.youthData.outcomesSummary;
        let enrolledInMonth = currentYouthEnrolled;
        let removed;
        let added;
        let heap = {}; // for tracking previous values

        // For each month
        for (var i = 0; i <= this.state.monthsBack; i++) {
            const targetMonth = moment().subtract(i, 'months').format('YYYY-MM');
            if (i === 0) {
                const discharged = youthExitFrequency.find(month => month.value === targetMonth);
                removed = discharged ? discharged.valueCount : 0;
                const enrolled = youthEnrolledFrequency.find(month => month.value === targetMonth);
                added = enrolled && enrolled.valueCount ? enrolled.valueCount : 0;
                const successOutcomes = youthOutcomesFrequency
                    ? youthOutcomesFrequency.find(month => month.value === targetMonth)
                    : null;
                const successes = successOutcomes ? successOutcomes.valueCount : 0;
                const percentSuccess = removed > 0 ? Math.round((successes / removed) * 100) : 0;
                youthDischarged.push(removed);
                dischargeOutcomes.push(percentSuccess);
            } else {
                const enrolled = youthEnrolledFrequency.find(month => month.value === targetMonth);
                added = enrolled && enrolled.valueCount ? enrolled.valueCount : 0;
                const discharged = youthExitFrequency.find(month => month.value === targetMonth);
                removed = discharged ? discharged.valueCount : 0;
                enrolledInMonth -= heap[i - 1].added - heap[i - 1].removed;
                const meanDays = discharged ? discharged.avg : 0;
                const successOutcomes = youthOutcomesFrequency
                    ? youthOutcomesFrequency.find(month => month.value === targetMonth)
                    : null;
                const successes = successOutcomes ? successOutcomes.valueCount : 0;
                const percentSuccess = removed > 0 ? Math.round((successes / removed) * 100) : 0;
                meanLengthOfService.push(Math.round(meanDays / 30));
                youthEnrolled.push(enrolledInMonth);
                youthDischarged.push(removed);
                dischargeOutcomes.push(percentSuccess);
            }
            heap[i] = { targetMonth, enrolledInMonth, removed, added };
        }
        youthDischarged = youthDischarged.splice(1, this.state.monthsBack).reverse(); // splice to remove first entry where i === 0
        const sum = dischargeOutcomes.reduce((a, b) => a + b, 0);
        this.setState({
            meanLengthOfService: meanLengthOfService.reverse(),
            meanLengthForCurrent: Math.round(avg / 30),
            youthEnrolled: youthEnrolled.reverse(),
            currentYouthEnrolled,
            youthDischarged,
            youthDischargedInRange: youthDischarged.reduce((a, b) => a + b, 0),
            dischargeOutcomes: dischargeOutcomes.reverse(),
            // get avgSuccessInRange by summing the discharge outcomes and dividing by the months where at least 1 youth was discharged
            avgSuccessInRange: (sum / dischargeOutcomes.filter(x => x !== 0).length).toFixed(2),
        });
    };

    getYouthData = async () => {
        const response = await client.query({
            query: youthEnrolledQuery,
            variables: {
                today: now,
                sites: this.props.checked,
            },
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
        });
        this.setState({
            youthData: response.data.roles,
            youthCount: response.data.roles.totalCount,
        });
    };

    getCycleData = async () => {
        if (this.props.checked.length > 0) {
            const filter = {
                AND: [
                    {
                        gte: {
                            field: 'closesAt',
                            string: now,
                        },
                    },
                    {
                        in: {
                            field: 'siteId',
                            int: this.props.checked,
                        },
                    },
                ],
            };
            const response = await client.query({
                query: dashboardQuery,
                variables: {
                    limit: this.state.cycleLimit,
                    deployFilter: filter,
                    assignmentIds: this.state.assignmentIds,
                },
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
            });

            this.setState({
                cycles: response.data.flows.deploymentList,
                cyclesLoaded: true,
                totalCycleCount: response.data.flows.filteredCount,
            });
        }
    };

    showMoreCycles = () => {
        this.setState(prevState => {
            return { ...prevState, cycleLimit: prevState.cycleLimit + 10 };
        });
    };

    getXaxis = () => {
        const { monthsBack } = this.state;
        let x = [];
        let adjust = monthsBack;

        let year = Math.floor(thisYear - (monthsBack - thisMonth) / 12);
        if (monthsBack > 12) {
            for (var e = 0; e < monthsBack; e++) {
                while (adjust > 12) {
                    adjust -= 12;
                }
                let index = thisMonth - adjust + e + 12;
                while (index >= 12) {
                    index -= 12;
                }
                if (index === 0) {
                    year += 1;
                }
                const label = `${index < 9 ? '0' : ''}${index + 1}/${year}`;
                x.push(label);
            }
        } else {
            for (var i = 0; i < monthsBack; i++) {
                while (adjust > 12) {
                    adjust -= 12;
                }
                let index = thisMonth - adjust + i + 12;
                while (index >= 12) {
                    index -= 12;
                }

                x.push(months[index]);
            }
        }
        this.setState({ xAxis: x });
    };

    monthsToLabel = m => {
        if (m < 12) {
            return `${m} months`;
        }
        const y = m / 12;
        return `${y !== 1 ? y : ''} year${y > 1 ? 's' : ''}`;
    };

    setMonths = monthsBack => {
        this.setState({ monthsBack });
    };

    setTools = assignmentIds => {
        this.setState({ assignmentIds });
    };

    expandAll = () => {
        this.setState({ collapseAll: false, forceUpdate: true });
    };

    collapseAll = () => {
        this.setState({ collapseAll: true, forceUpdate: true });
    };

    unForce = () => {
        this.setState({ forceUpdate: false });
    };

    render() {
        const chartData = [
            {
                data: this.formatLineData('youthEnrolled'),
                icon: IconUser,
                title: 'Total Wraparound Enrollment by Month',
                subtitle: `${this.state.currentYouthEnrolled} actively enrolled youth`,
                color: '#42a5f5',
                tooltip:
                    'This graph shows the total number of youth actively enrolled in Wraparound, and is calculated on the 15th of the month. This includes youth that were added to your program that month, and subtracts the number of youth that were discharged, to give you an estimate of how many youth were receiving services during the respective month.',
            },
            {
                data: this.formatLineData('youthDischarged'),
                icon: IconCheck,
                title: 'Number of Youth Discharged by Month',
                subtitle: `${this.state.youthDischargedInRange} youth discharged in last ${this.monthsToLabel(
                    this.state.monthsBack
                )}`,
                color: 'hsl(266, 70%, 50%)',
                tooltip:
                    'This graph shows the number of youth that were discharged during the respective month. For example, you could view how many youth had their exit date marked as September. This information is calculated on the 15th of the month.',
            },
            {
                data: this.formatLineData('dischargeOutcomes'),
                icon: IconStar,
                title: 'Percent of Youth Successfully Discharged',
                subtitle: `${this.state.avgSuccessInRange}% discharges were successful in last ${this.monthsToLabel(
                    this.state.monthsBack
                )}`,
                color: '#ffca28',
                tooltip:
                    'This graph only includes youth that were discharged during each month, and displays the percentage of discharges that were considered successful. A discharge is considered successful if the youth’s exit reason was marked as "Completed – Met goals on Plan of Care and stepped down to other services" or "Completed – Met goals on Plan of Care and discharged from services". This information is calculated on the 15th of the month.',
            },
            {
                data: this.formatLineData('meanLengthOfService'),
                icon: IconCal,
                title: 'Mean Length in Wraparound for Discharged Youth',
                subtitle: `${this.state.meanLengthForCurrent} months for actively enrolled youth`,
                color: '#66bb6a',
                tooltip:
                    'This graph includes only youth who have been discharged, and shows the average length of time youth were enrolled in Wraparound. For example, you could view the mean length of time in Wraparound for youth that had their exit date marked as September. This information is calculated on the 15th of the month.',
            },
        ];
        return (
            <div className={style.pageMargin}>
                <h2>Dashboard</h2>
                <div className={style.container}>
                    <ButtonBar />
                    <div className={style.columns}>
                        <div className={style.columnLeft}>
                            <DateRangeSelector
                                setter={this.setMonths}
                                val={this.state.monthsBack}
                                options={[6, 12, 60]}
                            />
                            {chartData.map(chart => (
                                <div key={chart.color} className={style.card}>
                                    <ChartCard
                                        data={chart.data}
                                        icon={chart.icon}
                                        title={chart.title}
                                        subtitle={chart.subtitle}
                                        color={chart.color}
                                        tooltip={chart.tooltip}
                                    />
                                </div>
                            ))}
                            {this.state.toolTree.length > 0 && (
                                <div className={style.card}>
                                    <MeanFidelity
                                        toolTree={this.state.toolTree && this.state.toolTree}
                                        timeLabel={this.monthsToLabel(this.state.monthsBack)}
                                        monthsBack={this.state.monthsBack}
                                        sites={this.props.checked}
                                    />
                                </div>
                            )}
                        </div>
                        {this.state.toolTree.length > 0 && (
                            <div className={style.columnRight}>
                                <div className={style.colFilter}>
                                    <ToolSelector
                                        setter={this.setTools}
                                        val={this.state.assignmentIds}
                                        toolTree={this.state.toolTree}
                                    />
                                </div>
                                <div className={style.card}>
                                    <div className={style.header}>
                                        <h4>Current Evaluation Cycles</h4>
                                        <div className={style.actions}>
                                            <div className={style.action} onClick={this.expandAll}>
                                                Expand All
                                            </div>{' '}
                                            <div>|</div>
                                            <div className={style.action} onClick={this.collapseAll}>
                                                Collapse All
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.cycles.length === 0 &&
                                        !this.state.cyclesLoaded &&
                                        [0, 1, 2, 3, 4, 5].map(key => (
                                            <Collapsable
                                                key={key}
                                                title=""
                                                content={<CyclePreview loading />}
                                                collapsed={this.state.collapseAll}
                                                forceUpdate={this.state.forceUpdate}
                                                undoForce={this.unForce}
                                            />
                                        ))}
                                    {this.state.cycles.length === 0 && this.state.cyclesLoaded && (
                                        <h3>No cycles match current selection</h3>
                                    )}
                                    {this.state.cycles.map(cycle => (
                                        <Collapsable
                                            key={cycle.deploymentId}
                                            title={`${cycle.label} - ${cycle.settings.site}`}
                                            content={
                                                <CyclePreview
                                                    sampleSize={cycle.respondentCount}
                                                    startDate={cycle.settings.startDate}
                                                    nowDate={now}
                                                    endDate={cycle.settings.endDate}
                                                    respondents={cycle.numCompleted}
                                                    responseRate={
                                                        cycle.numCompleted - cycle.optedOut - cycle.notConsented
                                                    }
                                                    responseRateDen={cycle.respondentCount - cycle.invalid}
                                                />
                                            }
                                            collapsed={this.state.collapseAll}
                                            forceUpdate={this.state.forceUpdate}
                                            undoForce={this.unForce}
                                        />
                                    ))}
                                </div>
                                {this.state.totalCycleCount > 0 && (
                                    <div className={style.flexIt}>
                                        {this.state.cycleLimit < this.state.totalCycleCount ? (
                                            <div className="btn btn-default" onClick={this.showMoreCycles}>
                                                Show More
                                            </div>
                                        ) : (
                                            <div>End of Current Cycles</div>
                                        )}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({ checked: state.app.sites.checked });
export default connect(mapStateToProps, { loadGroups })(Dashboard);
